// ********************************************************
// IMPORT
// ********************************************************
import "dotenv"
import "url-search-params-polyfill"
import { getUrlBoolean, getUrlString } from "@/helpers/UrlParam"
import injectScript from "@/helpers/InjectScript"

// ********************************************************
// VCONSOLE (frontend console on mobile)
// ********************************************************
const vconsole = getUrlBoolean("vconsole")

// ********************************************************
// LOCALE
// ********************************************************
// const locale = getUrlString("locale")

// const getIpLocale = async function () {
//   let resp = await fetch(
//     "https://pro.ip-api.com/json/?fields=61439&key=QQ6WlMWjilgBxGo"
//   )
//   let json = await resp.json()
//   let code = json.countryCode || "sg"
//   return code.toLowerCase()
// }

// ********************************************************
// INIT
// ********************************************************
const init = async function () {
  if (vconsole) {
    const module = await import("vconsole")
    const VConsole = module.default
    new VConsole()
  }

  // console.log(100, "VITE_BUILD", import.meta.env.VITE_BUILD)

  // const build = import.meta.env.VITE_BUILD
  // if (!locale && build === "international") {
  //   let code = await getIpLocale()
  //   window.location.href = `${window.location.origin}?locale=${code}`
  //   return
  // }

  await import("./vue")
}
init()
